import React from 'react';
import { Twitch, Twitter, CreditCard, Tv, Instagram, MessageCircle } from 'react-feather';
import { motion } from "framer-motion";

// Components
import Avatar from './components/Avatar';

import './App.css';

// Assets
import LinkBlock from './components/LinkBlock';

function App() {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const links = [
    {
      url: 'https://www.twitch.tv/gurdt',
      icon: <Twitch />,
      title: 'Twitch',
      caption: 'twitch.tv',
    },
    {
      url: 'https://www.twitter.com/gurdt',
      icon: <Twitter />,
      title: 'Twitter',
      caption: 'twitter.com',
    },
    {
      url: 'https://www.instagram.com/gurdt.n033/',
      icon: <Instagram />,
      title: 'Instagram',
      caption: 'instagram.com',
    },
    {
      url: 'https://discord.gg/r99bnseWxx',
      icon: <MessageCircle />,
      title: 'Discord',
      caption: 'discord.com',
    },
    {
      url: 'https://streamelements.com/gurdt/tip',
      icon: <Tv />,
      title: 'Streamelements Donation',
      caption: 'streamelements.com',
    },
    {
      url: 'https://paypal.me/geurt?country.x=NL&locale.x=nl_NL',
      icon: <CreditCard />,
      title: 'Payme',
      caption: 'paypal.me',
    },
  ];

  return (
    <>
      <div id="background" />
      <div className="bg-gradient-to-r p-[15px] from-[#0ABCFF] to-[#FF039B] rounded-3xl shadow-2xl my-10">
        <div className="mx-auto p-10 max-w-sm flex-col rounded-2xl bg-white text-black">
          <Avatar />
          <motion.div
            className="container"
            variants={container}
            initial="hidden"
            animate="visible"
          >
            {links.map((link, index) => {
              const linkClass = index === 4 ? "mt-10" : "";
              return (
                <motion.div key={index.toString()} className={linkClass} variants={item}>
                  <LinkBlock url={link.url} icon={link.icon} title={link.title} caption={link.caption} />
                </motion.div>
              )
            })}
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default App;
