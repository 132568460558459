import React from 'react';
import { motion } from 'framer-motion';

// Assets

// Props
import { LinkBlockProps } from './types';

const LinkBlock: React.FC<LinkBlockProps> = ({
  title,
  caption,
  url,
  icon
}: LinkBlockProps): JSX.Element => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <motion.div
        className="bg-gradient-to-r p-[5px] from-[#0ABCFF] to-[#FF039B] rounded-[1.25rem] mb-4"
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.8 }}
      >
        <div className="mx-auto p-4 max-w-sm flex-col rounded-2xl bg-white text-black">
          <div className="flex items-center">
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>{icon}</motion.div>
            <div className="ml-2">
              <div className="text-xs font-semibold">{title}</div>
              <div className="flex text-xs font-light text-gray-600">
                {caption}
                <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </a>
  );
}

export default LinkBlock;
