import React from 'react';
import { motion } from "framer-motion";

// Assets
import Gurdt from '../../assets/img/gurdt.png';

const Avatar: React.FC = (): JSX.Element => {
  return (
    <div className="text-center mb-12">
      <motion.img
        className="rounded-full shadow-2xl"
        alt="Gurdt"
        src={Gurdt}
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 50
        }}
      />

      <p className="pt-10 text-lg font-medium">[N033] Gurdt</p>
      <p className="text-m font-extrabold text-gray-900">Developer | Content creator</p>
    </div>
  );
}

export default Avatar;
